/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {useLocation} from 'react-router-dom'
import {sendAnswers, sendEmail, verifyUser} from 'api'
import logo from 'assets/img/logo.svg'
import darkBlueCircle from 'assets/img/light-circle.svg'
import lightBlueCircle from 'assets/img/dark-circle.svg'
import deleteIcon from 'assets/img/delete.svg'
import bgAnimation from 'assets/animation/bgAnimation.json'
import success from 'assets/animation/success.json'
import Lottie from 'lottie-react'
import {Pie} from 'react-chartjs-2'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {quotes} from './quotes'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

ChartJS.register(ArcElement, Tooltip, Legend)

const LandingPage = () => {
  const [toggler, setToggler] = useState(false)
  const questions = [
    {
      label: "Let's get to know you!",
      question: 'What is your highest level of education?',
      type: 'select',
      options: [
        'Primary',
        'Secondary',
        'Post-Secondary',
        'Undergraduate',
        "Bachelor's Degree",
        "Master's Degree",
        'Doctorate Degree',
        'Other',
      ],
    },
    {
      label: 'We are curious about your area of expertise',
      question: 'Which course did you study?',
      type: 'input-or-select',
      options: [
        'Medicine',
        'Pharmacy',
        'Natural Sciences',
        'Humanities and Social Sciences',
        'Business',
        'Law',
        'Engineering',
        'Computer Science',
        'Communication',
        'Arts',
      ],
      placeholder: 'Enter your course of study',
      description: 'Your course of study not on the list?',
      displayInput: toggler,
    },
    {
      label: 'Tell us about your current employment',
      question: 'Are you currently employed? If yes, what is your role?',
      type: 'input-or-two-options',
      twoOptions: ['I am employed', 'I am unemployed'],
      placeholder: 'Your current job position',
      description: '',
      displayInput: toggler,
    },
    {
      label: 'Help us understand you better',
      question: 'How satisfied are you with your current job?',
      type: 'scale',
      options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    },
    {
      label: 'Let us know if you want to change careers!',
      question: 'Do you wish to change your profession?',
      type: 'select',
      options: ['Yes', 'No', 'Maybe'],
    },
    {
      label: "We'll use your job experience to personalize your result.",
      question: 'What job positions have you previously held?',
      type: 'input-add',
      options: ['I have no previous experience'],
      description: 'Please add up to 5 most recent job positions',
      placeholder: 'Enter job position',
    },
    {
      label: 'Your interests matter when choosing a career',
      question: 'Do you have any hobbies or activities in your free time?',
      type: 'input-select',
      options: [
        'Writing',
        'Dancing',
        'Photography',
        'Cooking',
        'Painting',
        'Travelling',
        'Sports',
        'Music',
        'Hiking',
        'Gaming',
      ],
      placeholder: 'Enter hobby or activity',
    },
    {
      label: 'For better results, let us know what spikes your curiosity!',
      question: 'Are there any topics you are interested in?',
      type: 'add',
      placeholder: 'Enter topic',
    },
    {
      label: 'Your preferences matter',
      question: 'Do you prefer working in office or out in the field?',
      type: 'select',
      options: ['Office', 'Field', 'Combination'],
    },
    {
      label: 'Your career should match your personality',
      question: 'Would you say you are a:',
      type: 'multiple-select',
      options: [
        'Visual Person',
        'Math Person',
        'Storyteller',
        'Creative Person',
        'Communicator',
      ],
    },
    {
      label: 'Let us find you a career that matches your experience!',
      question: 'What kind of a role are you looking for in your next job?',
      type: 'select',
      options: ['Entry', 'Mid', 'Senior', 'Management'],
    },
  ]

  const [answers, setAnswers] = useState(Array(questions?.length)?.fill(''))
  const [step, setStep] = useState(0)
  const [object, setObject] = useState({})
  const [recommendedCareers, setRecommendedCareers] = useState('')
  const [otherHobby, setOtherHobby] = useState('')
  const [percentages, setPercentages] = useState([])
  const [jobsPositions, setJobPositions] = useState([])
  const [hobbies, setHobbies] = useState([])
  const [topics, setTopics] = useState([''])
  const [email, setEmail] = useState([])
  const [pairs, setPairs] = useState([])
  const [sent, setSent] = useState(false)
  const [headings, setHeadings] = useState([])
  const [texts, setTexts] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)
  const [selection, setSelection] = useState(false)
  const [currentQuote, setCurrentQuote] = useState('')
  const [displayPage, setDisplayPage] = useState(false)
  const [started, setStarted] = useState(false)
  const [token, setToken] = useState()
  const [errorMsg, setErrorMsg] = useState()
  const location = useLocation()

  const getCareers = () => {
    const newPairs = headings?.map(
      (career, index) => `${career} ${percentages[index]}%`
    )
    setPairs(newPairs)
  }

  useEffect(() => {
    if (percentages?.length > 0) {
      getCareers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentages])

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  }

  const data = {
    labels:
      headings?.length === 3 ? pairs : ['Career 1', 'Career 2', 'Career 3'],
    datasets: [
      {
        data: percentages,
        backgroundColor: ['#36208D', '#863CFF', '#b588ff'],
        borderColor: ['#fff', '#fff', '#fff'],
        borderWidth: 1,
      },
    ],
  }

  const handleSelectChange = (e) => {
    const newAnswers = [...answers]
    newAnswers[step] = e
    setAnswers(newAnswers)
    const element = document?.getElementById('animate')
    element?.classList?.remove('animate-fade')
  }

  const handleSelectUnselect = (e) => {
    if (toggler) {
      setToggler(!toggler)
    }
    const newAnswers = [...answers]
    if (newAnswers[step] === e) {
      newAnswers[step] = ''
    } else {
      newAnswers[step] = e
    }
    setAnswers(newAnswers)

    const element = document?.getElementById('animate')
    element?.classList?.remove('animate-fade')
  }

  const handleTwoOptions = (e) => {
    setSelection(true)
    if (toggler) {
      setToggler(!toggler)
    }
    const newAnswers = [...answers]
    if (newAnswers[step] === e) {
      newAnswers[step] = ''
    } else {
      if (e === 'I am unemployed') {
        newAnswers[step] = e
      } else {
        newAnswers[step] = ''
      }
    }
    setAnswers(newAnswers)

    const element = document?.getElementById('animate')
    element?.classList?.remove('animate-fade')
  }

  const handleInputChange = (e) => {
    const newAnswers = [...answers]
    newAnswers[step] = e.target.value
    setAnswers(newAnswers)
    const element = document?.getElementById('animate')
    element?.classList?.remove('animate-fade')
  }

  const handleMultipleOptions = (option) => {
    const newAnswers = [...answers]
    const index = newAnswers[step]?.indexOf(option)

    if (index === -1) {
      newAnswers[step] = [...newAnswers[step], option]
    } else {
      newAnswers[step] = newAnswers[step]?.filter((value) => value !== option)
    }
    if (otherHobby === '') {
      newAnswers[step] = newAnswers[step]?.filter((value) =>
        questions[step]?.options?.includes(value)
      )
    } else if (!newAnswers[step]?.includes(otherHobby) && otherHobby !== '') {
      newAnswers[step] = [...newAnswers[step], otherHobby]
    }

    setAnswers(newAnswers)
    const element = document?.getElementById('animate')
    element?.classList?.remove('animate-fade')
  }

  const handleMultipleSelect = (option) => {
    const newAnswers = [...answers]
    const index = newAnswers[step]?.indexOf(option)

    if (index === -1) {
      newAnswers[step] = [...newAnswers[step], option] // Add option if not already present
    } else {
      newAnswers[step] = newAnswers[step]?.filter((value) => value !== option) // Remove option if already present
    }

    setAnswers(newAnswers)
    const element = document?.getElementById('animate')
    element?.classList?.remove('animate-fade')
  }

  const notifyErrorOccured = () => {
    toast.warning('Please provide an answer.', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      className: 'custom-toast',
    })
  }

  const handleNextClick = () => {
    if (step < questions?.length - 1) {
      const element = document?.getElementById('animate')
      element?.classList?.remove('animate-fade')
      if (answers[step]?.length >= 1) {
        setStep(step + 1)
      } else {
        notifyErrorOccured()
      }
    } else {
      handleSubmit()
    }
  }

  const handleGoBack = () => {
    const element = document?.getElementById('animate')
    element?.classList?.remove('animate-fade')
    setStep(step - 1)
  }

  const handleSkip = () => {
    if (step < questions?.length - 1) {
      const newAnswers = [...answers]
      newAnswers[step] = ''
      setAnswers(newAnswers)
      const element = document?.getElementById('animate')
      element?.classList?.remove('animate-fade')
      setStep(step + 1)
    } else {
      handleSubmit()
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNextClick()
    }
  }

  const transformText = (text) => {
    const textWithoutSources = text
      ?.replaceAll('【5†source】', '')
      ?.replaceAll('#', '-')
    const lines = textWithoutSources?.split('\n')
    return lines?.map((paragraph, index) => {
      const isHeading = /^\d/.test(paragraph)
      return (
        <p key={index} style={{marginTop: 20}}>
          {paragraph?.split('**')?.map((part, i) => {
            if (i % 2 === 0 && !isHeading) {
              return part
            } else {
              return (
                <span className="font-bold text-accent-green" key={i}>
                  {part}
                </span>
              )
            }
          })}
        </p>
      )
    })
  }

  const {mutate, isLoading} = useMutation('sendAnswers', sendAnswers, {
    onSuccess: (data) => {
      setRecommendedCareers(data)
      const recommendationRates = data?.content?.match(
        /Recommendation Rate: (\d+)%/g
      )
      const percentages = recommendationRates?.map((rate) => {
        const matches = rate?.match(/\d+/)
        return matches ? parseInt(matches[0]) : null
      })
      setPercentages(percentages)

      const textWithoutSources = data?.content?.replaceAll('#', '-')
      const lines = textWithoutSources?.split('\n')

      let currentHeading = null
      let currentText = ''

      lines?.forEach((line) => {
        const isHeading = /^\d/.test(line)
        if (isHeading) {
          // If there was a previous heading and text, push them to the arrays
          if (currentHeading !== null && currentText?.trim() !== '') {
            headings?.push(currentHeading)
            texts?.push(transformText(currentText))
          }
          currentHeading = line?.trim()
          currentText = '' // Reset the current text for the new heading
        } else {
          // Append the line to the current text with a newline if text is not empty
          if (currentText !== '') {
            currentText += '\n'
          }
          currentText += line?.trim()
        }
      })

      // Push the last heading and text after the loop ends
      if (currentHeading !== null && currentText?.trim() !== '') {
        headings?.push(currentHeading)
        texts?.push(transformText(currentText))
      }
    },
    onError: (err) => {
      setDisplayPage(false)
      setErrorMsg(err?.response?.data?.message)
    },
  })

  const {mutate: handleSetEmail} = useMutation('sendEmail', sendEmail, {
    onSuccess: () => {
      setSent(true)
    },
    onError: () => {},
  })

  const handleSubmit = () => {
    answers?.forEach((item, index) => {
      if (Array.isArray(item)) {
        const flattened = item?.join(', ')
        object[`a${index + 1}`] = flattened
      } else if (item !== '') {
        object[`a${index + 1}`] = item
      }
    })

    mutate({token: token, data: object})
  }

  const handleJobPositions = (e, i) => {
    let copyLinks = [...jobsPositions]
    copyLinks[i] = e.target.value
    setJobPositions([...copyLinks])
  }

  const updateAnswers = (value) => {
    const newAnswers = [...answers]
    newAnswers[step] = value
    setAnswers(newAnswers)
  }

  const removeJobPositions = (i) => {
    let copyLinks = [...jobsPositions]
    copyLinks?.splice(i, 1)
    setJobPositions([...copyLinks])
    updateAnswers([...copyLinks])
  }

  const handleHobbies = (e, i) => {
    let copyLinks = [...hobbies]
    copyLinks[i] = e.target.value
    setHobbies([...copyLinks])
  }

  const removeHobbies = (i) => {
    handleUpdateOptions(hobbies[i])
    let copyLinks = [...hobbies]
    copyLinks?.splice(i, 1)
    setHobbies([...copyLinks])
  }

  const handleTopics = (e, i) => {
    let copyLinks = [...topics]
    copyLinks[i] = e.target.value
    setTopics([...copyLinks])
  }

  const removeTopics = (i) => {
    let copyLinks = [...topics]
    copyLinks?.splice(i, 1)
    setTopics([...copyLinks])
    updateAnswers([...copyLinks])
  }

  const handleAddOptions = (value) => {
    const newAnswers = [...answers]
    const index = newAnswers[step]?.indexOf(value)

    if (index === -1) {
      newAnswers[step] = [...newAnswers[step], value]
    }

    setAnswers(newAnswers)
    const element = document?.getElementById('animate')
    element?.classList?.remove('animate-fade')
  }

  const handleUpdateOptions = (value) => {
    const newAnswers = [...answers]

    // Check if the value exists in the answers[step] array
    const index = newAnswers[step]?.indexOf(value)

    // If the value exists in answers[step], remove it
    if (index !== -1) {
      newAnswers[step]?.splice(index, 1)
    }

    setAnswers(newAnswers)
    const element = document?.getElementById('animate')
    element?.classList?.remove('animate-fade')
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const emailUser = () => {
    handleSetEmail({
      token: token,
      data: {
        email: email,
        answers: object,
        content: recommendedCareers?.content,
      },
    })
  }

  const setRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * quotes.length)
    setCurrentQuote(quotes[randomIndex])
  }

  const goToMainPage = () => {
    window.location.href = 'https://www.livecourse.io/'
  }

  const {mutate: checkUser} = useMutation('verifyUser', verifyUser, {
    onSuccess: (data) => {
      if (!data?.uid) {
        window.location.href =
          'https://www.livecourse.io/auth/sign-in?logintype=uzorAI'
      } else {
        setDisplayPage(true)
      }
    },
    onError: () => {
      window.location.href =
        'https://www.livecourse.io/auth/sign-in?logintype=uzorAI'
    },
  })

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search)
    const token = searchParams?.get('token')
    if (token) {
      setToken(token)
      checkUser(token)
    } else {
      window.location.href =
        'https://www.livecourse.io/auth/sign-in?logintype=uzorAI'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.search])

  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      setRandomQuote()

      const intervalId = setInterval(setRandomQuote, 10000)

      return () => clearInterval(intervalId)
    }, 10000)

    return () => clearTimeout(initialTimeout)
  }, [])

  useEffect(() => {
    const element = document?.getElementById('animate')
    element?.classList?.add('animate-fade')
  }, [step])

  return (
    <div
      className={
        recommendedCareers?.content?.length > 1
          ? 'flex overflow-hidden relative justify-center font-dm-sans bg-faint-gray lg:py-20 min-h-screen'
          : 'flex overflow-hidden relative justify-center font-dm-sans bg-faint-gray lg:py-20 min-[1460px]:py-60 min-h-screen'
      }
    >
      <div className="hidden lg:block fixed -top-10 min-[1460px]:top-20">
        <Lottie
          animationData={bgAnimation}
          loop={true}
          className="w-[1000px] -z-10"
        />
      </div>
      <div className="md:rounded-xl px-10 lg:px-28 bg-pale-purple z-10 w-full max-w-[634px] pb-20 flex flex-col items-center">
        <div className="mt-20 z-10">
          <img src={logo} alt="dexude" className="h-10"></img>
        </div>
        {displayPage ? (
          <>
            {!started ? (
              <div className="w-full flex flex-col justify-center items-center mt-20 text-center">
                <p className="text-accent-purple font-bold text-3xl mb-6">
                  Uzo
                </p>
                <div>
                  <p>Hey, I’m Uzo, your AI companion.</p>
                  <p className="mt-4">
                    I’m here to help you achieve a fulfilling career by
                    recommending the best career path based on your personality,
                    level of experience, interest and passion.
                  </p>
                </div>
                <button
                  className="w-full p-3 rounded-lg font-bold mt-12 bg-accent-purple shadow-md text-white"
                  onClick={() => setStarted(true)}
                >
                  Start
                </button>
              </div>
            ) : (
              <>
                {recommendedCareers?.length === 0 && (
                  <div className="flex gap-x-2 mt-10">
                    {questions?.map((item, i) => {
                      return (
                        <div key={i}>
                          <img
                            src={step > i ? darkBlueCircle : lightBlueCircle}
                            alt="step"
                            className="w-3.5"
                          ></img>
                        </div>
                      )
                    })}
                  </div>
                )}
                {!isLoading && recommendedCareers?.length === 0 && (
                  <div className="flex flex-col mt-16 w-full" id="animate">
                    <div className="text-lg mb-6 font-bold text-accent-purple text-center">
                      <p>
                        {step !== 3
                          ? questions[step]?.label
                          : answers[2]?.length > 0 &&
                              answers[2] !== 'I am unemployed'
                            ? questions[step]?.label
                            : setStep(step + 1)}
                      </p>
                    </div>
                    <div>
                      {step !== 3
                        ? questions[step]?.question
                        : answers[2]?.length > 0 &&
                            answers[2] !== 'I am unemployed'
                          ? questions[step]?.question
                          : setStep(step + 1)}
                    </div>
                    {questions[step]?.type === 'input' && (
                      <input
                        className="border mt-6 w-full p-3 bg-pale-purple rounded-lg"
                        placeholder="Your Answer"
                        value={answers[step]}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        maxLength={100}
                      ></input>
                    )}
                    {questions[step]?.type === 'add' && (
                      <>
                        <div>
                          <p>{questions[step]?.description}</p>
                        </div>
                        {topics?.map((link, i) => {
                          return (
                            <div
                              className="flex justify-between border mt-6 w-full p-3 bg-pale-purple rounded-lg"
                              key={i}
                            >
                              <input
                                className="w-full bg-pale-purple"
                                placeholder={questions[step]?.placeholder}
                                key={i}
                                value={link}
                                onChange={(e) => handleTopics(e, i)}
                                onBlur={(e) => handleAddOptions(e.target.value)}
                                maxLength={100}
                              />
                              <div className="ml-5">
                                <img
                                  src={deleteIcon}
                                  alt="delete"
                                  onClick={() => removeTopics(i)}
                                  className="cursor-pointer w-6"
                                />
                              </div>
                            </div>
                          )
                        })}
                        <div className="mt-8 border-t pt-4">
                          {topics?.length < 5 && (
                            <p
                              className="text-accent-purple font-bold mt-4 cursor-pointer"
                              onClick={() => {
                                setTopics([...topics, ''])
                              }}
                            >
                              + Add topic
                            </p>
                          )}
                        </div>
                      </>
                    )}
                    {questions[step]?.type === 'select' && (
                      <div className="flex flex-wrap gap-3 mt-3 relative">
                        {questions[step]?.options?.map((i) => (
                          <div
                            key={i}
                            className={
                              answers[step] === i
                                ? 'cursor-pointer border w-fit bg-medium-purple text-white font-medium rounded-lg p-2'
                                : 'cursor-pointer border w-fit border-medium-purple text-medium-purple font-medium rounded-lg p-2'
                            }
                            onClick={() => handleSelectChange(i)}
                          >
                            {i}
                          </div>
                        ))}
                      </div>
                    )}
                    {questions[step]?.type === 'input-select' && (
                      <>
                        <div className="flex flex-wrap gap-3 mt-3">
                          {questions[step]?.options?.map((i) => (
                            <div
                              key={i}
                              className={
                                answers[step]?.includes(i)
                                  ? 'cursor-pointer border w-fit bg-medium-purple text-white font-medium rounded-lg p-2'
                                  : 'cursor-pointer border w-fit border-medium-purple text-medium-purple font-medium rounded-lg p-2'
                              }
                              onClick={() => handleMultipleSelect(i)}
                            >
                              {i}
                            </div>
                          ))}
                        </div>
                        <div className=" mt-8 border-t pt-4">
                          <p>{questions[step]?.description}</p>
                          {hobbies?.length < 5 && (
                            <p
                              className="text-accent-purple font-bold mt-4 cursor-pointer"
                              onClick={() => {
                                setHobbies([...hobbies, ''])
                              }}
                            >
                              + Add another
                            </p>
                          )}
                        </div>
                        {hobbies?.length > 0 &&
                          hobbies?.map((link, i) => {
                            return (
                              <div
                                className="border mt-6 p-3 bg-pale-purple rounded-lg flex w-full justify-between"
                                key={i}
                              >
                                <input
                                  className="w-full bg-pale-purple"
                                  placeholder={questions[step]?.placeholder}
                                  key={i}
                                  value={link}
                                  onChange={(e) => handleHobbies(e, i)}
                                  onBlur={(e) =>
                                    handleAddOptions(e.target.value)
                                  }
                                  maxLength={100}
                                />
                                <div className="ml-5">
                                  <img
                                    src={deleteIcon}
                                    alt="delete"
                                    onClick={() => removeHobbies(i)}
                                    className="cursor-pointer w-6"
                                  />
                                </div>
                              </div>
                            )
                          })}
                      </>
                    )}
                    {questions[step]?.type === 'input-add' && (
                      <>
                        <div className="flex flex-wrap gap-3 mt-3">
                          {questions[step]?.options?.map((i) => (
                            <div
                              key={i}
                              className={
                                answers[step]?.includes(i)
                                  ? 'cursor-pointer border w-fit bg-medium-purple text-white font-medium rounded-lg p-2'
                                  : 'cursor-pointer border w-fit border-medium-purple text-medium-purple font-medium rounded-lg p-2'
                              }
                              onClick={() => {
                                handleMultipleOptions(i)
                                setJobPositions([])
                              }}
                            >
                              {i}
                            </div>
                          ))}
                        </div>
                        {answers[step][0] !==
                          'I have no previous experience' && (
                          <div className=" mt-8 border-t pt-4">
                            <p>{questions[step]?.description}</p>
                            {jobsPositions?.length < 5 && (
                              <p
                                className="text-accent-purple font-bold mt-4 cursor-pointer"
                                onClick={() => {
                                  setJobPositions([...jobsPositions, ''])
                                }}
                              >
                                + Add job position
                              </p>
                            )}
                          </div>
                        )}
                        {jobsPositions?.length > 0 &&
                          jobsPositions?.map((link, i) => {
                            return (
                              <div
                                className="flex justify-between border mt-6 w-full p-3 bg-pale-purple rounded-lg"
                                key={i}
                              >
                                <input
                                  className="w-full bg-pale-purple "
                                  placeholder={questions[step]?.placeholder}
                                  value={link}
                                  onChange={(e) => handleJobPositions(e, i)}
                                  onBlur={(e) =>
                                    handleAddOptions(e.target.value)
                                  }
                                  maxLength={100}
                                />
                                <div className="ml-5">
                                  <img
                                    src={deleteIcon}
                                    alt="delete"
                                    onClick={() => removeJobPositions(i)}
                                    className="cursor-pointer w-6"
                                  />
                                </div>
                              </div>
                            )
                          })}
                      </>
                    )}
                    {questions[step]?.type === 'input-or-select' && (
                      <>
                        <div className="flex flex-wrap gap-3 mt-3">
                          {questions[step]?.options?.map((i) => (
                            <div
                              key={i}
                              className={
                                answers[step]?.includes(i)
                                  ? 'cursor-pointer border w-fit bg-medium-purple text-white font-medium rounded-lg p-2'
                                  : 'cursor-pointer border w-fit border-medium-purple text-medium-purple font-medium rounded-lg p-2'
                              }
                              onClick={() => handleSelectUnselect(i)}
                            >
                              {i}
                            </div>
                          ))}
                        </div>
                        <div className=" mt-8 border-t pt-4">
                          <p>{questions[step]?.description}</p>
                          <p
                            className="text-accent-purple font-bold mt-4 cursor-pointer"
                            onClick={() => {
                              handleSelectUnselect('')
                              setToggler(!toggler)
                            }}
                          >
                            + Add Course
                          </p>
                        </div>
                        {questions[step]?.displayInput === true && (
                          <input
                            className="border mt-6 w-full p-3 bg-pale-purple rounded-lg"
                            placeholder={`${questions[step]?.placeholder}`}
                            value={answers[step]}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            maxLength={100}
                          ></input>
                        )}
                      </>
                    )}
                    {questions[step]?.type === 'input-or-two-options' && (
                      <>
                        <div className="flex flex-wrap gap-3 mt-3">
                          {questions[step]?.twoOptions?.map((i) => (
                            <div
                              key={i}
                              className={
                                (i === 'I am unemployed' &&
                                  answers[step]?.includes(i)) ||
                                (i === 'I am employed' &&
                                  selection &&
                                  !answers[step]?.includes('I am unemployed'))
                                  ? 'cursor-pointer border w-fit bg-medium-purple text-white font-medium rounded-lg p-2'
                                  : 'cursor-pointer border w-fit border-medium-purple text-medium-purple font-medium rounded-lg p-2'
                              }
                              onClick={() => handleTwoOptions(i)}
                            >
                              {i}
                            </div>
                          ))}
                        </div>
                        <div className=" mt-8 border-t pt-4">
                          {answers[step] !== 'I am unemployed' && selection && (
                            <input
                              className="border mt-6 w-full p-3 bg-pale-purple rounded-lg"
                              placeholder={`${questions[step]?.placeholder}`}
                              value={answers[step]}
                              onChange={handleInputChange}
                              onKeyPress={handleKeyPress}
                              maxLength={100}
                            ></input>
                          )}
                        </div>
                      </>
                    )}
                    {questions[step]?.type === 'multiple-select' && (
                      <>
                        <div className="flex flex-wrap gap-3 mt-3">
                          {questions[step]?.options?.map((i) => (
                            <div
                              key={i}
                              className={
                                answers[step]?.includes(i)
                                  ? 'cursor-pointer border w-fit bg-medium-purple text-white font-medium rounded-lg p-2'
                                  : 'cursor-pointer border w-fit border-medium-purple text-medium-purple font-medium rounded-lg p-2'
                              }
                              onClick={() => handleMultipleSelect(i)}
                            >
                              {i}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    {questions[step]?.type === 'scale' &&
                      answers[2]?.length > 0 &&
                      answers[2] !== 'I am unemployed' && (
                        <div className="flex flex-wrap gap-1 mt-3 w-full lg:justify-between">
                          {questions[step]?.options?.map((i) => (
                            <div
                              key={i}
                              className={
                                answers[step] === i
                                  ? 'cursor-pointer border bg-medium-purple text-white text-sm font-medium rounded-full px-2 md:py-1 md:px-3'
                                  : 'cursor-pointer border border-medium-purple text-sm text-medium-purple font-medium rounded-full px-2 md:py-1 md:px-3'
                              }
                              onClick={() => handleSelectChange(i)}
                            >
                              {i}
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                )}
                {!isLoading && recommendedCareers?.length === 0 && (
                  <button
                    className={`w-full p-3 rounded-lg font-bold mt-8 shadow-md bg-accent-purple text-white`}
                    onClick={handleNextClick}
                  >
                    {step < questions?.length - 1 ? 'Next' : 'Submit'}
                  </button>
                )}
                {!isLoading &&
                  step < questions?.length - 1 &&
                  (step === 1 || step === 2 || step === 3) && (
                    <button
                      className={`w-full p-3 rounded-lg font-bold mt-6 border border-medium-purple text-medium-purple`}
                      onClick={handleSkip}
                    >
                      Skip
                    </button>
                  )}
                {!isLoading &&
                  recommendedCareers?.length === 0 &&
                  step !== 0 && (
                    <button
                      className={`w-full p-3 rounded-lg font-bold mt-6 border border-medium-purple text-medium-purple`}
                      onClick={handleGoBack}
                    >
                      Go Back
                    </button>
                  )}
                {isLoading ? (
                  <div className="flex flex-col mt-12">
                    <div className="flex flex-col text-center items-center justify-center animate-pulse my-auto text-dark-purple">
                      <p>Uzo AI is generating your result. Please wait…</p>
                      <div className="loader mt-8"></div>
                      <p className="mt-8">This process could take up to 60s</p>
                    </div>
                    <p className="text-accent-purple text-base text-center pt-8 mt-20 lg:mt-auto font-bold italic">
                      "{currentQuote}"
                    </p>
                  </div>
                ) : (
                  recommendedCareers?.content?.length > 1 && (
                    <div className="max-w-[600px] mt-14">
                      <p className="text-center text-dark-purple font-bold text-md">
                        Based on your Personality, Experience and Passion, here
                        is your optimised Career recommendation
                      </p>
                      {headings?.length === 3 && (
                        <div className="flex flex-col md:flex-row items-center gap-4 mt-8 md:mt-0">
                          <div className="text-sm md:order-last">
                            {headings?.map((heading, i) => {
                              return (
                                <div
                                  className="flex items-center gap-2 mb-2"
                                  key={i}
                                >
                                  <div
                                    className={`rounded-full w-2.5 h-2.5 ${i === 0 ? 'bg-[#36208D]' : i === 1 ? 'bg-[#863CFF]' : 'bg-[#b588ff]'}`}
                                  ></div>
                                  <p className="font-medium">{pairs[i]}</p>
                                </div>
                              )
                            })}
                          </div>
                          <div id="chart-container" className="!max-w-[160px]">
                            <Pie data={data} options={options} />
                          </div>
                        </div>
                      )}
                      <div className="w-full flex flex-col lg:flex-row justify-between text-center font-bold bg-dark-purple text-sm rounded-xl p-2 cursor-pointer">
                        {headings?.map((item, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => setSelectedTab(i)}
                              className={
                                selectedTab === i
                                  ? 'bg-accent-purple p-2 rounded-xl w-full text-white items-center flex justify-center'
                                  : 'bg-dark-purple p-2 rounded-xl w-full text-white items-center flex justify-center'
                              }
                            >
                              {item}
                            </div>
                          )
                        })}
                      </div>
                      <div className="lg:!w-[500px] mt-8">
                        {headings
                          ?.filter(
                            (heading) => heading === headings[selectedTab]
                          )
                          ?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="font-bold text-dark-purple">
                                  {item}
                                </div>
                                <div>{texts[selectedTab]}</div>
                              </div>
                            )
                          })}
                      </div>
                      <div className="flex flex-col mt-20 bg-dark-purple rounded-lg p-8">
                        <p className="text-lg font-bold text-white mb-4">
                          Email me my Uzo result
                        </p>
                        <div className="flex w-full shadow-lg">
                          <input
                            type="email"
                            className="border rounded-l-lg w-full px-4"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmail}
                            maxLength={100}
                          />
                          {!sent ? (
                            <button
                              className={`text-white p-3 rounded-r-lg font-bold bg-accent-purple h-12 flex w-20`}
                              onClick={emailUser}
                            >
                              Send
                            </button>
                          ) : (
                            <button
                              className={`text-white p-3 rounded-r-lg font-bold bg-accent-purple h-12 flex w-20`}
                            >
                              <Lottie
                                animationData={success}
                                loop={false}
                                className="h-12 -mt-3"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            )}
          </>
        ) : (
          <div>
            {!errorMsg ? (
              <div className="loader mt-32"></div>
            ) : (
              <div className="mt-28 text-center">
                <p>{errorMsg}</p>
                <button
                  className="text-white w-full p-3 rounded-lg font-bold mt-8 bg-accent-purple"
                  onClick={goToMainPage}
                >
                  Go Back
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <ToastContainer
        style={{minWidth: '350px'}}
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        progress={undefined}
        theme="colored"
      />
    </div>
  )
}

export default LandingPage
