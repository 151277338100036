export const quotes = [
  'A job is an opportunity, a career is a journey, and employment is the destination.',
  'In the dance of employment, perseverance leads to performance.',
  'Building a career is like constructing a skyscraper; it requires a solid foundation and relentless effort.',
  'Getting a job is a transaction, building a career is a transformation.',
  'The path to employment may be winding, but each turn brings new opportunities.',
  'A job is a paycheck, a career is a purpose.',
  'In the garden of employment, resilience is the seed of success.',
  'A career is not just about climbing the ladder; it’s about leaving a legacy.',
  'Securing a job is the first step, but building a career is the journey.',
  'In the symphony of employment, passion plays the leading role.',
  'A job is a door, a career is the hallway, and employment is the room.',
  'Every job application is a chance to showcase your potential.',
  'Building a career requires patience, persistence, and a touch of audacity.',
  'In the maze of employment, adaptability is the compass.',
  'A job is a chapter, a career is the story.',
  'Each rejection letter is a lesson in resilience on the path to employment.',
  'Building a career is like sculpting; every setback shapes your masterpiece.',
  'A job is a piece of the puzzle, but a career is the picture it completes.',
  'In the journey of employment, attitude determines altitude.',
  'Securing a job is a victory, but building a career is the triumph.',
  'A career is not just about what you achieve; it’s about who you become.',
  'In the labyrinth of employment, determination is the guiding light.',
  'A job is a step, a career is the staircase, and employment is the summit.',
  'Building a career is like planting a tree; it takes time, but the harvest is worth it.',
  'Every job interview is an opportunity to showcase your potential.',
  'A job is a beginning, a career is a journey, and employment is the destination.',
  'In the tapestry of employment, ambition threads the needle.',
  'Securing employment is the start of the journey; building a career is the destination.',
  'A job is a canvas, a career is the masterpiece.',
  'In the mosaic of employment, resilience fills the gaps.',
  'A career is the sum of your experiences, skills, and aspirations.',
  'Every job offer is a stepping stone in the construction of your career.',
  'Building a career is like weaving a tapestry; each thread contributes to the final picture.',
  'A job is a milestone, a career is the journey, and employment is the destination.',
  'In the landscape of employment, innovation is the fertile soil for growth.',
  'Securing employment is the first step; carving a career is the adventure.',
  'A career is not just a path; it’s a journey of self-discovery and growth.',
  'Every job application is an opportunity to demonstrate your value.',
  'In the journey of employment, resilience is the compass.',
  'A job is a chapter in your career story; make each one count.',
  'Building a career is like climbing a mountain; the journey is tough, but the view from the top is worth it.',
  'A career is not just about what you do; it’s about who you become in the process.',
  'Every job opportunity is a chance to take a step closer to your career goals.',
  'In the marathon of employment, perseverance is the finish line.',
  'Securing employment is the beginning; building a career is the evolution.',
  'A job is a door that opens to countless opportunities for career growth.',
  'Building a career is like constructing a building; it requires a strong foundation and steady progress.',
  'In the garden of employment, ambition is the seed that blossoms into success.',
  'A career is a journey of self-discovery, growth, and impact.',
  'Every job experience shapes your career path; embrace each opportunity as a stepping stone toward success.',
]
