import axios from 'axios'

const proxy = 'https://uzo-backed-for37lq4eq-ew.a.run.app'

export const sendAnswers = async ({token, data}) => {
  const response = await axios(proxy + `/ai/create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const sendEmail = async ({token, data}) => {
  const response = await axios(proxy + `/recommendation`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const verifyUser = async (id) => {
  const response = await axios(proxy + `/verifyUser?token=${id}`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}
